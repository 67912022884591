

import React, { useEffect, useRef, useState } from 'react';
import OT, { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';
import { Alert, Col, Row, Input, Typography, Tooltip } from 'antd';
import SendForm from './Components/SendForm';
import './assets/css/style.css'
import './assets/css/responsive.css'
import io from "socket.io-client"
import { Button, Container, Image } from 'react-bootstrap';
import Toast_Container from './Components/Toast_Container';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
// images
import logo from './assets/images/banner.png'
import page_logo from './assets/images/fanslogo.png'
import coin1 from './assets/images/coin1.png'
import live_icon from './assets/images/live-icon.png'
import eye from './assets/images/eye-icon.png'
import expand from './assets/images/expand-icon.png'
import invite_friends from './assets/images/invite-friends.png'
import icons_rei from './assets/images/icons-rei.png'
import cancel from './assets/images/croos.png'
import phone from './assets/images/Phone_Icon.png'
import mic from './assets/images/mic.png'
import video from './assets/images/video-icon.png'

// mobile 
import mobile_audio from './assets/mobile_images/audio.png'
import mobile_emoji from './assets/mobile_images/emoji.png'
import mobile_gift from './assets/mobile_images/gift.png'
import mobile_invitation from './assets/mobile_images/invitation.png'
import mobile_user from './assets/mobile_images/user.png'
import mobile_video from './assets/mobile_images/video.png'



import { FaCamera, FaEye, FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash } from "react-icons/fa6";
import { RiChatOffLine, RiChat1Fill, RiChatOffFill } from "react-icons/ri";
import { MdCancel, MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { HiMiniSpeakerXMark, HiMiniSpeakerWave, HiEye } from "react-icons/hi2";


const ShowStatus = ({ message }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <h4>{message}</h4>
      {/* <button style={{ width: 200, height: 50 }} onClick={() => window.location.replace('https://fansseefeet.com/my/dashboard')}>Go Back to your profile</button> */}
    </div>
  )
}
const SideButton = ({ src, alt, onClick, isActive, gifts, userType }) => {
  return (
    <>
      <div
        className={`opener ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        <Image className='side_image' src={src} alt={alt} fluid />
      </div>
    </>
  )
}
const apiKey = '47917231';
// const url = 'http://localhost:3001/'

const url = 'https://streaming.hostvent.com/'
const socket = io.connect(url, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
})

function App() {
  const uniqueId = uuidv4();
  const searchParams = new URLSearchParams(window.location.search);
  const UserType = searchParams.get("type");
  const user_name = searchParams.get("user_name");
  const userId = searchParams.get("user_id")
  const model_id = searchParams.get("model_id");
  const model_name = searchParams.get("model_name");
  const model_gender = searchParams.get("model_gender");
  const model_age = searchParams.get("model_age");
  const model_url = searchParams.get("model_url");
  const is_user = searchParams.get("is_user");
  const [roomId, setRoomId] = useState(searchParams.get("room_id"))
  const [streamId, setStreamID] = useState(searchParams.get("stream_id"))
  const [sessionId, setSessionId] = useState(searchParams.get("sessionId"));
  const [token, seToken] = useState(searchParams.get("token"));
  const [Permission, setPermissionStatus] = useState('');

  const screenWidth = window.innerWidth
  const [isLoading, setLoading] = useState(false)
  const [isRoomCreated, setIsRoomCreated] = useState(false)
  const [mySocketId, setMySocketId] = useState(null)
  const [sessionDetail, setSessionDetail] = useState(null)
  const [isJoinRoom, setIsJoinRoom] = useState(false)
  const [roomUsers, setRoomUsers] = useState([])
  const [status, setStatus] = useState('')
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [_expend, setExpend] = useState(false)
  const [totalUser, setTotalUser] = useState(0)
  const [sessionStates, setSessionStates] = useState({
    showControls: false,
    publishVideo: true,
    publishAudio: true,
  })

  const gifts = [10, 30, 100, 500]
  const [_amount, setAmount] = useState(0)
  const [sendGiftLoading, setGiftLoading] = useState(false)
  const publisherRef = useRef()
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const fancyCoinInterval = useRef(null);
  const [chatVisible, setChatVisible] = useState(true);

  const [isActive, setIsActive] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [showCoinPopup, setShowCoinPopup] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const searchBarRef = useRef(null);


  // console.log("isRoomCreated", isRoomCreated)
  // console.log("sessionDetail", sessionDetail)
  // console.log("UserType", UserType)

  console.log(isUser)

  useEffect(() => {
    if (isJoinRoom && UserType == 'join' && _amount == 0) {
      fancyCoinInterval.current = setInterval(() => {
        setShowCoinPopup(true)
      }, 20000);
      return () => { clearInterval(fancyCoinInterval?.current) }
    } else {
      if (fancyCoinInterval.current) {
        clearInterval(fancyCoinInterval.current)
      }
    }
  }, [isJoinRoom, UserType, _amount])
  useEffect(() => {
    if (mySocketId != null && UserType != undefined) {
      if (UserType == 'live') {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          alert("Video is not supported.");
          setStatus("Video is not supported.")
          setLoading(false)
        } else {
          if (!isRoomCreated) {
            setPermissionStatus(true)
            setStatus("Creating room...")
            createRoom()
          }
        }
      }
      else if (UserType == 'join' && roomId && UserType && user_name && userId) {
        JoinRoom()
      }
    }
  }, [UserType, mySocketId])
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    socket.on('me', id => {
      socket.emit('updateSocketId', { mySocketId: id, UserType: UserType, userId: userId })
      setMySocketId(id)
      console.log("my socket id", id)
    })
    socket.on('createRoom', data => {
      console.log("after creating stream", data)
      if (data.success) {
        setStreamID(data.room.id)
        setSessionDetail(data.room)
        setRoomId(data.room.roomId)
        setIsRoomCreated(true)
        fetch('https://fansseefeet.com/api/create-streaming', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_id: userId,
            room_id: data.room.roomId,
            session_id: data.room.sessionId,
            token: data.room.token,
            stream_id: data.room.id
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setStatus('')
            console.log("stream created successfully")
          })
          .catch(error => {
            console.error('There was an error!', error);
            // handle error
          });

      }
    })
    socket.on('updateSocketId', data => {
      console.log("on Update socket id", data)
    })
    socket.on('joinRoom', data => {
      if (data.success) {
        FetchGift(userId)
        setStatus('')
        setIsJoinRoom(true)
      } else {
        setStatus(data.message)
      }
    })
    // get when new user join 
    socket.on('newJoin', data => {
      setRoomUsers(prevUsers => ([...prevUsers, data]));
    });
    // get when user out
    socket.on('disconnectedUser', data => {
      setRoomUsers(prevUsers => prevUsers.filter(user => user.userId != data.userId));
    });
    socket.on('onDisconnect', data => {
      setStatus(data.message)
    });
    socket.on('onExpel', data => {
      setStatus(data.message)
    });
    socket.on(toString(roomId), data => {
      console.log(data)
      if (data?.data?.userId != userId && data.type == 'message') {
        setMessages(prev => [...prev, data.data])
      } else if (data.type == 'stream_end') {
        setStatus(data.message)
      } else if (data.type == 'newJoin') {
        setTotalUser(data.data);
      } else if (data.type == 'removedUser') {
        setTotalUser(data.data);
      }
    })
    socket.on("get_gift", data => {
      console.log("on Get gift", data)
      ShowToast({ message: data.message, type: 'success' })
    })

    const eventHandler = () => {
      OnDisconnect(userId, UserType)
    };

    window.addEventListener('beforeunload', eventHandler);
    window.addEventListener('hashchange', eventHandler);
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.value = 'Initial Value';
    }
    // window.location.hash = uuidv4();
    return () => {
      window.removeEventListener('beforeunload', eventHandler);
      window.removeEventListener('hashchange', eventHandler);
    };

  }, [userId, UserType])
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F11') {
        event.preventDefault(); // Prevent the default F11 action
        toggleFullscreen();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const OnDisconnect = (id, type) => {
    fetch(`${url}disconnect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, userType: type }),
    })
      .then((response) => response.json())
      .then((data) => {

      })
      .catch((error) => {
        logError(error)
      });
  };

  const logError = (error) => {
    fetch(`${url}logError`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error: error.message }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to log error on server');
        }
        // Optionally handle the success response from the server
      })
      .catch((error) => {
        console.error('Failed to log error on server:', error);
      });
  };
  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].screenX;
    handleSwipeGesture();
  };

  const handleSwipeGesture = () => {
    if (touchStartX - touchEndX > 100) {
      // Swipe left
      console.log("Swipe left detected");
      setChatVisible(true);
    } else if (touchEndX - touchStartX > 100) {
      // Swipe right
      console.log("Swipe right detected");
      setChatVisible(false);
    }
  };


  const createRoom = () => {
    let data = {
      mySocketId: mySocketId,
      roomId: uniqueId,
      userId: userId,
    }
    socket.emit('createRoom', data)
  }

  const JoinRoom = () => {
    setStatus("Loading......")
    let data = {
      socketId: mySocketId,
      roomId: roomId,
      username: user_name,
      userId: userId,
    }
    socket.emit('joinRoom', data)
  }

  const RemoveUser = (val) => {
    setRoomUsers(prevUsers => prevUsers.filter(user => user.userId != val.userId));
    socket.emit("onExpel", { userId: val.userId, socketId: val.socketId, streamId: streamId, roomId: roomId })
  }

  const handleSend = (e) => {
    e.preventDefault()
    console.log("before condition", message)
    if (!message) {
      console.log("after condition", message)
      return null
    }
    setMessages(prev => [...prev, { username: user_name, message: message, socketId: mySocketId, streamId: streamId, userId: userId }])
    socket.emit("message", { roomId: roomId, message: message, username: user_name, socketId: mySocketId, streamId: streamId, userId: userId })
    setMessage('')
  }

  const handleEndStream = () => {
    window.location.replace('https://fansseefeet.com');
  }

  const SendGift = (amount) => {
    if (!userId || !model_id) {
      return alert("There is some error.")
    } else if (_amount < amount) {
      alert("Sorry, you do not have enough balance in your account. Please add funds.");
      window.open('https://fansseefeet.com/login', '_blank');
      return
    }
    const requestBody = {
      customer_id: userId,
      model_id: model_id,
      amount: amount
    };
    const sendGift = () => {
      setGiftLoading(true)
      fetch('https://fansseefeet.com/api/send-gift', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          setGiftLoading(false)
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.status == 200) {
            setGiftLoading(false)
            FetchGift(userId)
            ShowToast({ message: data.data, type: 'success' })
            socket.emit("send_gift", { user_name: user_name, amount: amount, id: streamId })
          } else {
            ShowToast({ message: data.data, type: 'error' })
          }
        })
        .catch(error => {
          setGiftLoading(false)
          console.error('There was a problem with the POST request:', error);
        });
    };

    const handleConfirmation = () => {
      const confirmed = window.confirm('Would you like to send the gift?');
      if (confirmed) {
        sendGift();
      } else {
        console.log('Gift sending cancelled.');
      }
    };

    handleConfirmation();
  }

  const FetchGift = (id) => {
    fetch(`https://fansseefeet.com/api/get-gift/${id}`)
      .then(response => {
        if (response.status === 200) {
          return response.text();
        } else {
          throw new Error('Failed to fetch data');
        }
      })
      .then(result => {
        let data = JSON.parse(result);
        if (data.status == 200) {
          setAmount(data.data)
        }
      })
      .catch(error => console.log('Error:', error));
  }

  const ShowToast = ({ message, type }) => {
    toast(message, {
      type: type
    });
  }
  const StopCamera = () => {
    setSessionStates(prev => ({ ...prev, publishVideo: !sessionStates.publishVideo }))
  }
  const StopeMic = () => {
    setSessionStates(prev => ({ ...prev, publishAudio: !sessionStates.publishAudio }))
  }

  const filterUniqueUsers = (users) => {
    const uniqueUsers = [];
    const seenUserIds = new Set();

    for (const user of users) {
      if (!seenUserIds.has(user.userId)) {
        seenUserIds.add(user.userId);
        uniqueUsers.push(user);
      }
    }

    return uniqueUsers;
  };
  // Get unique room users
  const uniqueRoomUsers = filterUniqueUsers(roomUsers);
  const handleExpand = () => {
    setExpend(!_expend)
  }
  if (status) {
    return ShowStatus({ message: status })
  }
  return (
    <div>
      {screenWidth > 1024 ?
        <div style={{ position: 'relative' }}>
          <header>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="banner">
                    <div className="banner-inner">
                      <img className="img-fluid" src={logo} alt="Banner" />
                    </div>
                    <div className="page-logo">
                      <img className="img-fluid" src={page_logo} alt="Fans Logo" />
                    </div>

                    <li style={{ cursor: 'pointer' }} className='banner-btn list-unstyled' onClick={() => handleEndStream()}>BACK TO WEBSITE</li>

                  </div>
                </div>
              </div>
            </div>
          </header>

          {UserType == 'join' &&
            <section className="section-lists pd-40">
              <div className="container bg-sec">
                <Row className='d-flex justify-content-between'>
                  <div className="lft-name-listing">
                    <ul className="ft-list">
                      <li className="brd">Name:</li>
                      <li className="brd">Age:</li>
                      <li className="brd">Sex:</li>
                    </ul>
                  </div>
                  <div className="lft-name-listing">
                    <ul className="ft-list pink">
                      <li>{model_name}</li>
                      <li>{model_age}</li>
                      <li>{model_gender}</li>
                    </ul>
                  </div>
                </Row>

              </div>
            </section>
          }

          {UserType == 'join' &&
            <Container className='d-flex p-3 mb-4' style={{ backgroundColor: '#15304c', borderRadius: 10 }}>
              <Row className='d-flex'>
                <Col className=''>
                  <ul className='list-unstyled text-white p-0 m-0 d-flex'>
                    <li className='d-flex flex-row align-items-center' style={{ fontSize: 24, marginRight: 20 }}>
                      <img src={coin1} alt="coin1" style={{ marginRight: 10 }} />
                      Send Coins
                    </li>
                    {gifts.map((v, i) => {
                      return (
                        <li onClick={() => SendGift(v)} key={i} className='d-flex flex-row align-items-center' style={{ fontSize: 24, marginRight: 20, marginLeft: 20, border: '1px solid #f21366', backgroundColor: '#465665', padding: 10, borderRadius: 10, cursor: 'pointer' }}>
                          <img src={coin1} alt="coin1" style={{ marginRight: 10, width: 30, height: 30 }} />
                          {v} Coins
                        </li>
                      )
                    })}
                  </ul>
                </Col>
                {/* <Col className='d-flex align-items-center justify-content-end'>
                  <div className="live-kk live-call" onClick={() => window.open(model_url)}>
                    <span>Exist</span>
                  </div>
                </Col> */}
              </Row>
            </Container>
          }

          <Container fluid className='vh-100 d-flex mt-5 p-0'>
            <Row className="w-100 h-100">
              <Col className="flex-grow-1 h-100 d-flex  justify-content-center">
                <div className='w-100'>
                  {UserType == 'live' && sessionDetail != null && (
                    <OTSession
                      onError={(e) => {
                        setIsRoomCreated(false)
                      }}
                      apiKey={apiKey} sessionId={sessionDetail.sessionId} token={sessionDetail.token}>
                      <>
                        <div className="iner-cont d-flex align-items-center justify-content-between w-100 p-3" style={{ zIndex: 9999 }}>
                          <div className="live-kk"><span><img src={live_icon} />Live</span></div>
                          <ul className="bb-ll-list">
                            <li className="view"><span><img src={eye} />{totalUser}</span></li>
                            <li onClick={() => handleExpand()} className="expand"><span><img src={expand} /></span></li>
                          </ul>
                        </div>
                        <OTPublisher
                          properties={{
                            showControls: sessionStates.showControls,
                            fitMode: 'cover',
                            publishVideo: sessionStates.publishVideo,
                            publishAudio: sessionStates.publishAudio,
                            enableStereo: true,
                            facingMode: 'user',
                            insertMode: "replace"
                          }}
                          ref={publisherRef}
                        />
                        <div className="iner-cont d-flex align-items-center justify-content-between w-100 bottom-0" style={{ zIndex: 9999, paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
                          {UserType == 'live' &&

                            <div className="sound">
                              <div onClick={() => StopCamera()} className="live-friend">
                                {sessionStates.publishVideo ?
                                  <FaVideo color='#f21366' size={30} />
                                  :
                                  <FaVideoSlash color='#f21366' size={30} />
                                }
                              </div>
                              <div onClick={() => StopeMic()} className="live-friend">
                                {sessionStates.publishAudio ?
                                  <FaMicrophone color='#f21366' size={30} />
                                  :
                                  <FaMicrophoneSlash color='#f21366' size={30} />
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </>
                    </OTSession>
                  )}


                  {UserType == 'join' && sessionId && token && isJoinRoom && (
                    <OTSession apiKey={apiKey} sessionId={sessionId} token={token} onConnect={e => setIsJoinRoom(true)}
                      onerror={(e) => setIsJoinRoom(false)}
                    >
                      <OTStreams>
                        <>
                          <div className="iner-cont d-flex align-items-center justify-content-between w-100 p-3" style={{ zIndex: 9999 }}>

                            <div className="live-kk"><span><img src={live_icon} />Live</span></div>
                            <ul className="bb-ll-list">
                              <li className="view"><span><img src={eye} />{totalUser}</span></li>
                              <li onClick={() => setExpend(!_expend)} className="expand"><span><img src={expand} /></span></li>
                            </ul>
                          </div>
                          <div className="iner-cont d-flex align-items-center justify-content-between w-100 p-3 bottom-0" style={{ zIndex: 9999 }}>
                            <div className='d-flex flex-row align-items-center'>
                              <div onClick={() => StopeMic()} style={{ marginLeft: 10 }}>
                                {sessionStates.publishAudio ?
                                  <HiMiniSpeakerWave color='#f21366' size={40} />
                                  :
                                  <HiMiniSpeakerXMark color='#f21366' size={40} />
                                }
                              </div>
                            </div>

                            <div className="live-kk live-call" onClick={() => window.open(model_url)}>
                              <img src={phone} alt="" />
                              <span>Invite Private</span>
                            </div>

                          </div>
                          <OTSubscriber
                            properties={{
                              showControls: false,
                              fitMode: 'cover',
                              subscribeToAudio: sessionStates.publishAudio,
                              subscribeToVideo: sessionStates.publishVideo,
                            }}
                            ref={publisherRef}
                          />

                          {UserType == 'join' && showCoinPopup &&
                            <div
                              className={`position-absolute d-flex align-items-center justify-content-center ${showCoinPopup ? 'popupSlide-in' : 'popupSlide-out'}`}
                              style={{
                                backgroundColor: "rgba(0,0,0,0.1)",
                                zIndex: 9999,
                                left: 0, right: 0, bottom: 0, top: 0
                              }}
                            >
                              <div
                                className="text-center position-relative"
                                style={{
                                  backgroundColor: "rgba(255,255,255,0.9)",
                                  borderRadius: 10,
                                  padding: 10,
                                }}
                              >
                                <h5>Please add fancy coin</h5>
                                <button
                                  style={{
                                    backgroundColor: "#ffdc00",
                                    padding: 10,
                                    border: "none",
                                    borderRadius: 15,
                                  }}
                                  onClick={() => window.open("https://fansseefeet.com/login", "_blank")}
                                >
                                  Add Coin
                                </button>
                                <MdCancel
                                  onClick={() => setShowCoinPopup(false)}
                                  size={30}
                                  style={{
                                    position: "absolute",
                                    right: -15,
                                    top: -15,
                                  }}
                                />
                              </div>
                            </div>
                          }
                        </>
                      </OTStreams>
                    </OTSession>
                  )}
                </div>
              </Col>
              {!_expend &&
                <>
                  <Col className="w-25 p-3 h-100" style={{ backgroundColor: '#15304c', marginRight: 5 }}>
                    <div className="d-flex flex-column h-100">
                      <div className="head-comment"><h2>Comments Feeds</h2></div>
                      <div className="flex-grow-1 overflow-auto" ref={containerRef}>
                        <ul className="list-unstyled">
                          {messages.map((item, index) => (
                            <li key={index} style={{ backgroundColor: '#465665', padding: 10, borderRadius: 10, marginBottom: 5 }}>
                              <h4>{item.username} :</h4>
                              <p style={{ padding: 0, color: 'white' }}>{item.message}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <SendForm
                        onChange={(e) => setMessage(e.target.value)}
                        message={message}
                        handleClick={handleSend}
                        onEmojiClick={(e) => setMessage(prev => prev + e.emoji)}
                      />
                    </div>
                  </Col>
                  {UserType == 'live' &&
                    <Col className="w-25 p-3" style={{ backgroundColor: '#15304c', marginRight: 5 }}>
                      <div className="head-comment"><h2>Active Users</h2></div>
                      {uniqueRoomUsers.map((val, i) => (
                        <li className='users' key={i}>
                          <span className="active-ttl text-white">
                            <img src={icons_rei} alt="icon" />
                            {val.username}
                          </span>
                          <img
                            onClick={() => RemoveUser(val)}
                            src={cancel}
                            alt="remove"
                          />
                        </li>
                      ))}
                    </Col>
                  }
                </>
              }
            </Row>
            <Toast_Container />
            <input
              type="text"
              ref={inputRef}
              style={{ position: 'absolute', left: '-9999px' }} // Hidden input
            />
          </Container>
        </div>
        :
        <>
          <Container onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} fluid className='vh-100 d-flex m-0 p-0 position-relative overflow-hidden' style={{ backgroundColor: '#ccc' }}>
            <Row className="w-100 h-100">
              <div className='w-100'>
                {UserType == 'live' && sessionDetail != null && (
                  <>
                    <OTSession
                      onError={(e) => {
                        console.log("error", e)
                      }}
                      apiKey={apiKey} sessionId={sessionDetail.sessionId} token={sessionDetail.token}>
                      <OTPublisher
                        properties={{
                          showControls: sessionStates.showControls,
                          fitMode: 'cover',
                          publishVideo: sessionStates.publishVideo,
                          publishAudio: sessionStates.publishAudio,
                        }}
                        ref={publisherRef}
                        eventHandlers={{
                          mediaStopped: e => console.log("When media screeem stoped", e)
                        }}
                        onError={(e) => console.log("publisher error", e)}
                      />
                    </OTSession>
                    <div>
                      <div className="position-absolute d-flex align-items-center justify-content-between w-100" style={{ zIndex: 9999, top: 5 }}>
                        <Button className='custom_buttons'>
                          <img src={live_icon} className="customIcon" />
                          Live
                        </Button>
                        <div>
                          <Button className='custom_buttons'>
                            End Stream
                          </Button>
                          <Button className='custom_buttons' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <HiEye color='white' size={20} style={{ marginRight: 5 }} />
                            {totalUser}
                          </Button>
                        </div>
                      </div>

                      <div className='side-buttons'>
                        <div
                          className={`opener`}
                          onClick={() => setIsUser(!isUser)}
                        >
                          <Image className='side_image' src={mobile_user} alt={"Open Users"} fluid />
                        </div>
                        <div onClick={() => StopCamera()} className="opener">
                          {sessionStates.publishVideo ?
                            <FaVideo color='#ffdc00' size={25} />
                            :
                            <FaVideoSlash color='#ffdc00' size={25} />
                          }
                        </div>
                        <div onClick={() => StopeMic()} className="opener">
                          {sessionStates.publishAudio ?
                            <FaMicrophone color='#ffdc00' size={25} className='side_image' />
                            :
                            <FaMicrophoneSlash color='#ffdc00' size={25} className='side_image' />
                          }
                        </div>
                        <div onClick={() => setChatVisible(!chatVisible)} className="opener">
                          {chatVisible ?
                            <RiChatOffFill color='#ffdc00' size={25} className='side_image' />
                            :
                            <RiChatOffLine color='#ffdc00' size={25} className='side_image' />
                          }
                        </div>
                        <div onClick={() => toggleFullscreen()} className="opener">
                          {isFullscreen ?
                            <MdFullscreenExit color='#ffdc00' size={28} className='side_image' />
                            :
                            <MdFullscreen color='#ffdc00' size={28} className='side_image' />
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {UserType == 'join' && sessionId && token && isJoinRoom && (
                  <OTSession apiKey={apiKey} sessionId={sessionId}
                    token={token}
                    onerror={e => console.log("error", e)}
                    onConnect={e => console.log("connected", e)}

                  >
                    <OTStreams>
                      <div>
                        <div className="position-absolute d-flex align-items-center justify-content-between w-100" style={{ zIndex: 9998 }}>
                          <Button className='custom_buttons'>
                            <img src={live_icon} className="customIcon" />
                            Live
                          </Button>
                          <div>
                            <Button className='custom_buttons' onClick={() => window.location.replace('https://fansseefeet.com/')}>
                              Exit
                            </Button>
                            <Button className='custom_buttons' style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
                              <HiEye color='white' size={20} style={{ marginRight: 5 }} />
                              {totalUser}
                            </Button>
                          </div>
                        </div>
                        <div className='side-buttons'>
                          <div
                            className={`opener ${isActive ? 'active' : ''}`}
                            onClick={() => setIsActive(!isActive)}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          >
                            <Image className='side_image' src={mobile_gift} alt={"mobile_gift"} fluid />
                            <div style={{ display: 'flex', justifyContent: 'center', flex: 1, marginRight: 10 }}>
                              {gifts.map((v, i) => {
                                return (
                                  <li onClick={() => SendGift(v)} key={i} className='coin_btn d-flex flex-row align-items-center justify-content-center'>
                                    {/* <img src={coin1} alt="coin1" style={{ marginRight: 3, width: '1rem' }} /> */}
                                    {v} Coins
                                  </li>
                                )
                              })}
                            </div>
                          </div>
                          <div
                            className={`opener`}
                            onClick={() => window.open(model_url)}
                          >
                            <Image className='side_image' src={mobile_invitation} alt={"Invite Modal"} fluid />
                          </div>
                          {/* <div onClick={() => StopCamera()} className="opener">
                            {sessionStates.publishVideo ?
                              <FaVideo color='#ffdc00' size={25} />
                              :
                              <FaVideoSlash color='#ffdc00' size={25} />
                            }
                          </div> */}
                          <div onClick={() => StopeMic()} className="opener">
                            {sessionStates.publishAudio ?
                              <HiMiniSpeakerWave color='#ffdc00' size={25} />
                              :
                              <HiMiniSpeakerXMark color='#ffdc00' size={25} />
                            }
                          </div>
                          <div onClick={() => setChatVisible(!chatVisible)} className="opener">
                            {chatVisible ?
                              <RiChatOffFill color='#ffdc00' size={25} className='side_image' />
                              :
                              <RiChatOffLine color='#ffdc00' size={25} className='side_image' />
                            }
                          </div>
                          <div onClick={() => toggleFullscreen()} className="opener">
                            {isFullscreen ?
                              <MdFullscreenExit color='#ffdc00' size={25} className='side_image' />
                              :
                              <MdFullscreen color='#ffdc00' size={25} className='side_image' />
                            }
                          </div>
                        </div>
                        {showCoinPopup &&
                          <div
                            className={`position-absolute d-flex align-items-center justify-content-center ${showCoinPopup ? 'popupSlide-in' : 'popupSlide-out'}`}
                            style={{
                              left: 0,
                              right: 0,
                              top: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0,0,0,0.1)",
                              zIndex: 9999,
                            }}
                          >
                            <div
                              className="text-center position-relative"
                              style={{
                                backgroundColor: "rgba(255,255,255,0.9)",
                                borderRadius: 10,
                                padding: 10,
                              }}
                            >
                              <h5>Please add fancy coin</h5>
                              <button
                                style={{
                                  backgroundColor: "#ffdc00",
                                  padding: 10,
                                  border: "none",
                                  borderRadius: 15,
                                }}
                                onClick={() => window.open("https://fansseefeet.com/login", "_blank")}
                              >
                                Add Coin
                              </button>
                              <MdCancel
                                onClick={() => setShowCoinPopup(false)}
                                size={30}
                                style={{
                                  position: "absolute",
                                  right: -15,
                                  top: -15,
                                }}
                              />
                            </div>
                          </div>
                        }

                        <OTSubscriber
                          properties={{
                            showControls: false,
                            fitMode: 'cover',
                            subscribeToVideo: sessionStates.publishVideo,
                            subscribeToAudio: sessionStates.publishAudio,
                          }}
                          ref={publisherRef}
                        />
                      </div>
                    </OTStreams>
                  </OTSession>
                )}
                <div
                  className={`position-absolute bottom-0 w-100 p-3 ${chatVisible ? 'slide-in' : 'slide-out'}`}
                  style={{ zIndex: 9998 }}
                >
                  <div className='chat_container overflow-y-scroll' ref={containerRef}>
                    <ul className="list-unstyled text-white">
                      {messages.map((item, index) => (
                        <li key={index} style={{ padding: 5 }}>
                          <h6 style={{ color: 'white', fontWeight: 'bold', marginBottom: 3 }}>{item.username}:</h6>
                          <p style={{ padding: 0, color: 'white', margin: 0 }}>{item.message}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <SendForm
                    onChange={(e) => setMessage(e.target.value)}
                    message={message}
                    handleClick={handleSend}
                    onEmojiClick={(e) => setMessage(prev => prev + e.emoji)}
                    isMobile={true}
                  />
                </div>
              </div>
            </Row>
            <Toast_Container />
            <input
              type="text"
              ref={inputRef}
              style={{ position: 'absolute', left: '-9999px' }} // Hidden input
            />
          </Container>
          {UserType == 'live' &&
            <div className={`drawer_inActive ${isUser ? 'drawer_active' : ''}`}>
              <div style={{ width: '85%', padding: 10, position: 'fixed' }}>
                <div className='d-flex justify-content-between align-items-center text-white'><h4>Active Users</h4>
                  <Button className='custom_buttons' onClick={() => setIsUser(false)}>
                    Close
                  </Button>
                </div>
                {uniqueRoomUsers.map((val, i) => (
                  <li className='users' key={i}>
                    <span className="active-ttl text-white">
                      <img src={icons_rei} alt="icon" />
                      {val.username}
                    </span>
                    <img
                      onClick={() => RemoveUser(val)}
                      src={cancel}
                      alt="remove"
                    />
                  </li>
                ))}
              </div>
            </div>
          }

        </>

      }
    </div >
  )
}

export default App;

