// import React from 'react'
// import { Form, Button, Row, Col } from 'react-bootstrap';
// import submit from '../assets/images/submit.png'
// function SendForm({ onChange, handleClick, message }) {
//     return (
//         <div className="input-sec">
//             <form style={{ display: 'flex', justifyContent: 'space-between' }} onSubmit={handleClick}>
//                 <input type="text" id="fname" name="fname" defaultValue="Type your message here"
//                     placeholder='Type your message here'
//                     onChange={onChange}
//                     value={message}
//                     style={{ width: '100%', marginRight: 10, backgroundColor: 'transparent', borderWidth: 0, color: 'white' }}
//                 />
//                 <input type="submit" value="" src={submit} />
//             </form>
//         </div>
//     )
// }

// export default SendForm



import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import submit from '../assets/images/submit.png';
import submit2 from '../assets/mobile_images/send-icon.png'
import { MdEmojiEmotions } from 'react-icons/md';
import EmojiPicker from 'emoji-picker-react';

function SendForm({ onChange, handleClick, message, onEmojiClick, isMobile = false }) {
    const [open, setOpen] = useState(false);
    const [textAreaHeight, setTextAreaHeight] = useState(40); // Initial height
    const emojiRef = useRef(null);
    const emojiButtonRef = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiRef.current && !emojiRef.current.contains(event.target) &&
                emojiButtonRef.current && !emojiButtonRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTextareaChange = (event) => {
        setTextAreaHeight(event.target.scrollHeight);
        onChange(event);
    };

    const handleSubmit = (event) => {
        setTextAreaHeight(40);
        setOpen(false);
        handleClick(event);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <>
            {!isMobile ?
                <>
                    {open && (
                        <div style={{ position: 'absolute', bottom: '100px', left: 10, zIndex: 1000 }} ref={emojiRef}>
                            <EmojiPicker
                                onEmojiClick={onEmojiClick}
                                width={330}
                                autoFocusSearch={false}
                            />
                        </div>
                    )}
                    <div style={{ paddingTop: 10 }}>
                        <form style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onSubmit={handleSubmit}>
                            <textarea
                                id="fname"
                                name="fname"
                                placeholder='Type your message here'
                                onKeyDown={handleKeyDown}
                                onChange={handleTextareaChange}
                                value={message}
                                rows={5}
                                ref={textAreaRef}
                                style={{
                                    width: '100%',
                                    marginRight: 10,
                                    backgroundColor: 'transparent',
                                    borderWidth: 0,
                                    color: 'white',
                                    resize: 'none',
                                    overflowY: 'auto',
                                    height: `${textAreaHeight}px`, // Set height dynamically based on state
                                    maxHeight: '100px'
                                }}
                                autoComplete='off'
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <div ref={emojiButtonRef}>
                                    <MdEmojiEmotions size={50} color='yellow' style={{ marginRight: 5 }} onClick={() => setOpen(!open)} />
                                </div>
                                <input type="submit" value="" src={submit} />
                            </div>
                        </form>
                    </div>
                </>
                :
                <>
                    {open && (
                        <div style={{ position: 'absolute', bottom: '70px', zIndex: 9998, display: 'flex', justifyContent: 'flex-end', width: '100%', right: 20 }}>
                            <div ref={emojiRef} style={{ width: 'calc(100% - 40px)' }}>
                                <EmojiPicker
                                    onEmojiClick={onEmojiClick}
                                    autoFocusSearch={false}
                                    width="100%"
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ backgroundColor: 'rgba(22,48,76,0.7)', paddingLeft: 20, paddingRight: 10, borderRadius: 100, overflow: 'hidden' }}>
                        <form style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onSubmit={handleSubmit}>
                            <textarea
                                id="fname"
                                name="fname"
                                placeholder='Type your message here'
                                onChange={handleTextareaChange} // Use handleTextareaChange instead of onChange
                                value={message}
                                rows={2}
                                ref={textAreaRef}
                                style={{
                                    width: '100%',
                                    marginRight: 10,
                                    backgroundColor: 'transparent',
                                    borderWidth: 0,
                                    color: 'white',
                                    resize: 'none',
                                    overflowY: 'auto',
                                    height: `${textAreaHeight}px`, // Set height dynamically based on state
                                    maxHeight: '40px',
                                    verticalAlign: 'center',
                                    outline: 'none',
                                    alignContent: 'center',
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    scrollbarWidth: 'none',
                                }}
                                autoComplete='off'
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <div ref={emojiButtonRef}>
                                    <MdEmojiEmotions size={30} color='yellow' style={{ marginRight: 5 }} onClick={() => setOpen(!open)} />
                                </div>
                                {/* <input type="submit" value="" src={submit2} style={{ width: 30, height: 30, color: 'red' }} /> */}
                                <button type='submit' style={{ backgroundColor: 'transparent', borderWidth: 0 }}>
                                    <img src={submit2} />
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            }
        </>
    );
}

export default SendForm;
